.absolute-m-t-l {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swal2-popup {
  background: white !important;
  color: white !important;
}
